@charset "UTF-8";

/**
 * ==============================================
 * Dot Pulse
 * ==============================================
 */
.dot-pulse {
  position: relative;
  left: -9999px;
  width: 14px;
  height: 14px;
  border-radius: 7px;
  background-color: #9575cd;
  color: #9575cd;
  box-shadow: 9999px 0 0 0px #9575cd;
  animation: dotPulse 1.5s infinite linear;
  animation-delay: 0.25s;
}

.dot-pulse::before,
.dot-pulse::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  width: 14px;
  height: 14px;
  border-radius: 7px;
  background-color: #9575cd;
  color: #9575cd;
}

.dot-pulse::before {
  box-shadow: 9973px 0 0 0px #9575cd;
  animation: dotPulseBefore 1.5s infinite linear;
  animation-delay: 0s;
}

.dot-pulse::after {
  box-shadow: 10025px 0 0 0px #9575cd;
  animation: dotPulseAfter 1.5s infinite linear;
  animation-delay: 0.5s;
}

@keyframes dotPulseBefore {
  0% {
    box-shadow: 9973px 0 0 0px #9575cd;
  }

  20% {
    box-shadow: 9973px 0 0 2.5px #9575cd;
  }

  40%,
  100% {
    box-shadow: 9973px 0 0 0px #9575cd;
  }
}

@keyframes dotPulse {
  0% {
    box-shadow: 9999px 0 0 0px #9575cd;
  }

  20% {
    box-shadow: 9999px 0 0 2.5px #9575cd;
  }

  40%,
  100% {
    box-shadow: 9999px 0 0 0px #9575cd;
  }
}

@keyframes dotPulseAfter {
  0% {
    box-shadow: 10025px 0 0 0px #9575cd;
  }

  20% {
    box-shadow: 10025px 0 0 2.5px #9575cd;
  }

  40%,
  100% {
    box-shadow: 10025px 0 0 0px #9575cd;
  }
}
