/* App.css */

html,
body {
  background-image: linear-gradient(to bottom, #fceffc, #f7f1fa, #fff7f0);
  margin: 0;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 100%;
  min-width: 1140px;
  overflow-x: hidden;

  font-family: 'LatoWeb', Lato, sans-serif;
  font-size: 16px;

  content: url(../../images/lesson_complete_hover_ic.svg)
    url(../../images/lesson_available_hover_ic.svg)
    url(../../images/rules_hover_ic.svg) url(../../images/back_ic.svg);
}

button:hover,
button:focus {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.24), 0 5px 5px 0 rgba(0, 0, 0, 0.19);
}

.socialBtn:hover,
.socialBtn:focus,
.socialBtn:active {
  border-radius: 30px 30px 30px 30px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.24),
    0 1px 1px 1px rgba(0, 0, 0, 0.19);
}

/* Header CSS */
.header {
  min-height: 70px !important;
  box-shadow: 0 5px 20px 0 rgba(227, 226, 232, 0.5);
  background-color: #fffffd;
}

.header .navbar {
  width: 1110px;
  margin: auto;
}

.navbar {
  padding: 13px 0;
}

.PageContent {
  max-width: 100% !important;
  padding-top: 30px;
  padding-bottom: 55px;
}

.Loader {
  margin-top: 48px;
  margin-bottom: 32px;
}

.HeaderLogin {
  background: #683da1;
  margin-right: 16px;
  border-radius: 25px;
  height: 32px;
  width: 128px;
  border: none;
  padding: 0px;
  font-size: 19px;
  font-weight: 800;
  height: 40px;
}

.HeaderRegister {
  background: #ffa00a;
  border-radius: 25px;
  margin-right: 90px;
  height: 32px;
  width: 240px;
  border: none;
  padding: 0px;
  font-size: 19px;
  font-weight: 800;
  height: 40px;
}

.ClodeeLogo {
  max-width: 163px;
  max-height: auto;
  line-height: 46px;
}

.center-ic {
  /* margin-right: 224px; */
  margin-right: 204px;
}

.ranking,
.notification,
.program,
.course {
  width: 40px;
  height: 40px;
  margin-right: 18px;
}

.lang-selector {
  height: 82px;
  width: 58px;
  position: absolute;
  top: 0px;
  left: 762px;
}

.lang-selector.general {
  left: 1051px;
}

.lang-selector img {
  display: inline-block;
  position: relative;
  height: 82px;
  overflow: visible;
}

.lang-selector p {
  position: relative;
  color: white;
  bottom: 50px;
  margin: auto;
  font-size: 17px;
  text-align: center;
}

.lang-selector ul {
  position: relative;
  list-style: none;
  margin: auto;
  width: 80%;
  top: -89px;
  text-align: center;
  padding: 5px 0px 5px 0px;
  background-color: #fafafad0;
  border-radius: 8px;
  user-select: none;
  color: #888;
  z-index: 100;
}

.lang-selector ul:hover {
  display: block;
}

.lang-selector ul li:hover {
  color: black;
}

.circle {
  display: block;
  width: 40px;
  height: 40px;
  margin: auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  background-color: #927bba;
}

.avatar-wrapper {
  display: inline-block;
  width: 40px;
  height: 40px;
  background-color: transparent !important;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  border: 2px solid #927bba;
}

.rating-avatar .avatar-wrapper {
  margin: -14px 0px;
}

.avatar-circle {
  display: none;
  width: 38px;
  height: 38px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  border: 2px solid #927bba;
}

.avatar {
  display: block;
  position: relative;
  top: -2px;
  left: -2px;
  width: 40px;
  height: 40px;
  background-color: #927bba;
  border: 2px solid #927bba;
  object-fit: cover;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
  overflow: hidden;
}

.notification-container {
  position: relative;
}

.red-oval {
  right: 21px;
  height: 18px;
  min-width: 18px;
  top: 0px;
  position: absolute;
  /* display: inline-block; */
  background-color: #fc476e;
  border-radius: 50%;
  padding: 0 5px 0 6px;
  text-align: center;
  color: white;
  font-size: 12px;
  font-weight: 700;
}

.red-oval span {
  margin-bottom: 14px;
}

/* End of Header CSS */

/* CSS for LogIn, Register, Restore Password */
.LogContainer {
  border-radius: 10px;
  background-color: #fffffd;

  margin: auto;
  height: 694px;
  width: 540px;
  /** updated **/
  /* padding: 35px 123px 100px 123px; */

  -webkit-box-shadow: 0 20px 20px -20px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 20px 20px -20px rgba(0, 0, 0, 0.2);
  box-shadow: 0 20px 20px -20px rgba(0, 0, 0, 0.2);
}

.log-header-title {
  margin: 30px 0 55px 0;
  font-size: 25px;
}

.LogHeaderTitle,
.PassChangeLogHeaderTitle,
.RegisterLogHeaderTitle {
  margin: 0px auto 100px auto;
  padding-top: 35px;
}

.Inputs {
  margin: 0 auto;
  text-align: center;
}

.LogInputControl {
  border-radius: 25px;
  border: none;
  padding-left: 28px;
  padding-right: 28px;
  font-size: 18px;
  height: 46px;
}

.LogInputField {
  border-radius: 25px;
  border: solid 2px #e4e4e1;
  width: 295px;
  height: 50px;
  margin-bottom: 8px;
}

.PasswordChangeButton,
.LoginButton {
  border-radius: 25px;
  width: 295px;
  height: 40px;
  background-color: #683da1;
  border: none;
  margin: 50px auto 25px auto;
  font-size: 19px;
  font-weight: 800;
  height: 50px;
}

.RegisterButton {
  border-radius: 25px;
  background-color: #683da1;
  width: 295px;
  height: 40px;
  margin-top: 71px;
  margin-bottom: 55px;
  border: none;
  font-size: 19px;
  font-weight: 800;
  height: 50px;
}

.card {
  background-color: #fffffd;
  border: none;
  border-radius: 10px;

  -webkit-box-shadow: 0 20px 20px -20px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 20px 20px -20px rgba(0, 0, 0, 0.1);
  box-shadow: 0 20px 20px -20px rgba(0, 0, 0, 0.1);
}

.card-header {
  background: #fffffd;
  border: 1px solid transparent;
  height: 95px;
  line-height: 48px;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 10px;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.course-header {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

.card-header:not(.collapsed) {
  border-radius: 10px 10px 10px 10px;

  -webkit-box-shadow: 0 12px 20px -5px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: 0 12px 20px -5px rgba(0, 0, 0, 0.09);
  box-shadow: 0 12px 20px -5px rgba(0, 0, 0, 0.09);
}

.card-header.collapsed {
  border-radius: 10px 10px 10px 10px;
}

.card-header[aria-expanded='true'] {
  border-radius: 10px 10px 0 0;
}

.card-header-active {
  border: 1px solid #ffd14c !important;
}

.card-header > h5 {
  font-weight: 600;
  font-style: normal;
  height: 95px;
  line-height: 62px;
  vertical-align: middle;
  letter-spacing: normal;
  color: #494947;
  text-decoration: none;
}

.card-header * {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

[data-toggle='collapse'].card-header:after {
  display: none;
}

.step-two-card,
.step-two-header {
  box-shadow: none !important;
}

.step-two-title {
  font-size: 22px !important;
}

.step-two-title:after {
  content: url(../../images/expand.svg);
  float: right;
  margin-top: 6px;
  margin-right: 9px;
}

.step-two-subtitle1 {
  font-size: 20px;
  font-weight: 500;
  color: #494947;
  margin-bottom: 18px;
}

.step-two-subtitle2 {
  font-size: 20px;
  font-weight: 500;
  color: #494947;
  margin-bottom: -14px;
}

.step-two-line {
  margin: 20px 0 22px 0;
  width: 728px;
  height: 1.25px;
  background-color: #e4e4e1;
}
/*
.list-group:after {
  content: url(../../images/collapse.svg);
}

.list-group:after {
  content: url(../../images/collapse.svg);
  color: #63686b;
  margin-right: 12.8px;
  float: right;
  transition: all linear 0.25s;
  height: 95px;
  line-height: 62px;
  vertical-align: middle;
  text-decoration: none;
  outline: 0;
  right: 10px;
}
*/

[data-toggle='collapse'].collapsed:after {
  /* font-size: 19px;
  line-height: 23px; */
  height: 68px;
  vertical-align: middle;
  transform: rotate(180deg);
  right: 10px;
}

.collapsesvg {
  margin-right: 26px;
  margin-left: 14px;
  height: 34px;
  width: 34px;
}

.collapse-svg-img {
  height: 34px;
  width: 34px;
}

.collapse-title {
  width: 100%;
  margin-top: auto;
  margin-bottom: auto;
  font-size: 19px;
  margin-top: 1px;
}

.collapsed {
  color: #63686b;
  text-decoration: none;
  outline: none;
}

.list-group-item {
  user-select: none;
  width: 546px;
  margin: auto;
  vertical-align: middle;
  font-weight: bold;
  font-style: normal;
  font-size: 21px;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  padding: 0px 0px 0px 35px;
  color: #fffffd;
}

.list-group input[type='checkbox'] {
  display: none;
  line-height: 95px;
  vertical-align: middle;
  height: 95px;
}

.list-group input[type='checkbox'] + .list-group-item {
  cursor: pointer;
  border-radius: 10px;
  height: 95px;
  line-height: 89px;
  vertical-align: middle;
  color: #fffffd;
  border: 2px solid transparent;
}

.list-group input[type='checkbox'] + .list-group-item:before {
  content: url(../../images/checkmark_ic.svg);
  display: none;
  float: right;
  padding-top: 2px;
  margin-right: 30px;
}

.list-group input[type='checkbox']:checked + .list-group-item {
  color: #fffffd;
  display: initial;
}

.list-group input[type='checkbox']:checked + .list-group-item:before {
  color: inherit;
  display: initial;
}

.center-content {
  width: 730px;
  margin: 0px auto 0px auto;
}

.program-card {
  margin: 10px auto 30px auto;
}

.program-card-text {
  font-size: 19px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #7f7f7f;
}

.card-choice {
  height: 350px;
  width: 350px;
  margin: 0 auto;
  border-radius: 10px;
}

.card-choice * {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.program-card-header {
  font-size: 26px;
  margin-bottom: 60px;
}

.card-body {
  padding: 35px 35px 0 35px;
}

.card-body:last-child {
  padding-bottom: 45px;
}

.card-body-course {
  padding: 35px 0 0 0;
}

.card-body-course .list-group-item {
  width: 100%;
  height: 150px !important;
}

.card-input-element {
  display: none;
}

.card-input-element:checked + .card-input {
  box-shadow: 0 0 0px 2px #ffd14c, 0 20px 20px -20px rgba(0, 0, 0, 0.1);
}

.card-input:hover {
  cursor: pointer;
}

.card-text {
  /* width: 289px;
  height: 42px; */
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 20px;
  letter-spacing: -0.222083px;
  color: #63686b;
}

.course-step-two {
  position: relative;
}

.course-step-two .list-group input[type='checkbox']:checked + .list-group-item {
  border: 2px solid black;
}

.course-step-two .list-group input[type='checkbox'] + .list-group-item:before {
  content: none;
}

.course-flag-img {
  position: absolute;
  right: 30px;
  top: 60px;
  width: 60px;
  height: 60px;
}

/* Registration Step 3 CSS methods*/

.step3-layout {
  margin: 0 auto 0 auto;
}

.step3-card-choice {
  height: 21.875em;
  width: 22.5em;
  border-radius: 10px;
  background-color: #fffffd;
}

.step3-card-footer {
  margin-top: 3.25em;
  /* width: 14.25em; */
  width: 100%;
  height: 1.438em;
  font-size: 1.188em;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}

.step3-card-input-element {
  display: none;
}

.step3-card-input:hover {
  cursor: pointer;
}

.step3-card-input-element:checked + .step3-card-input {
  box-shadow: 0 0 0px 2px #ffd14c, 0 20px 20px -20px rgba(0, 0, 0, 0.1);
  color: #ffa00a;
}

.step3-card-input-element:not(:checked) + .step3-card-input {
  color: #7782d6;
}

/* End Step 3  */

/* Registration Step 4 CSS methods */
.step4-card {
  color: #63686b;
  background-color: #fffffd;
  width: 45.625em;
  height: 22.5em;
  border-radius: 10px;
  margin: 0 auto 0 auto;

  -webkit-box-shadow: 0 20px 20px -20px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 20px 20px -20px rgba(0, 0, 0, 0.2);
  box-shadow: 0 20px 20px -20px rgba(0, 0, 0, 0.2);
}

.step4-card-header {
  background: #fffffd;
  height: 4.875em;
  margin: 0 2.188em 0 2.188em;
  padding: 35px 0 20px 2px;
  border-bottom: 1px solid #d1d1d1;
}

.step4-card-body {
  padding: 35px;
  height: 17.5em;
}

.step4-right-col {
  margin-right: 1em;
  width: 18.063em;
}

.step4-left-col {
  width: 20.938em;
  float: right;
}

.step4-right-col,
.step4-left-col {
  display: inline-block;
}

.step4-btn-input-switch-wrapper {
  padding: 0;
  width: 340px;
  margin-left: -4px;
}

.step4-card-text {
  width: 18.063em;
  height: 2.625em;
  margin-bottom: 28px;
  font-size: 1.063em;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.22px;
  color: #63686b;
}

.step4-input {
  width: 20.938em;
  height: 3.75em;
  border-radius: 5px;
  border: solid 1px #c2c7c8;
  /* margin-bottom: 1.563em; */
}

.day-btn {
  background: #eeeeee;
  border-radius: 5px;
  width: 2.688em;
  height: 2.688em;
  line-height: 2.688em;
  margin-right: 4.8px;
  text-align: center;
  cursor: pointer;
  user-select: none;
}

.day-btn > span {
  display: block;
}

.day-cb {
  display: none;
}

.day-btn input:checked + span {
  background-color: #7782d6;
  border-radius: 5px;
  color: #fffffd;
}

.custom-control-label::before {
  background: #e4e4e1;
  border: none;
  cursor: pointer;
}

.custom-switch .custom-control-label::after {
  height: 23px;
  width: 23px;
  border-radius: 50%;
  top: 2%;
  left: -2.65rem;
  background: #afafaf;
  cursor: pointer;
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #e4e4e1;
  border: none;
}

.custom-control-input:checked ~ .custom-control-label::after {
  background-color: #e4e4e1;
  border: none;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #9575cd;
  height: 23px;
  width: 23px;
  border-radius: 50%;
  right: 0%;
  left: -2.25rem;
  top: 2%;
}

.day-active {
  background-color: #7782d6;
}

/* End Registration Step 4 */

.LinkRestoreText {
  margin: 10px auto 0 auto;
  font-size: 17px;
  line-height: 20px;
  color: #494947;
  align-content: center;
}

.error-text {
  margin: 10px auto 0 auto;
  font-size: 17px;
  line-height: 20px;
  color: #eb1f7f;
  align-content: center;
}

.social {
  margin: 70px auto 100px auto;
  text-align: center;
  display: block;
}

.icon_vk {
  cursor: pointer;
}

.icon_fb {
  cursor: pointer;
  margin: 0 36px 0 36px;
}

.icon_g {
  cursor: pointer;
}

/* End CSS for LogIn, Register, Restore Password */

/* Footer CSS */
.footer {
  width: 100%;
  line-height: 98px;
  background-color: #fffffd;
  bottom: 0;
  /* margin-top: 1em; */
  /* position: absolute; */
}

.footer-content {
  width: 1110px;
  margin: auto;
  /* display: flex; */
}

.footer-copyright-date {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #494947;
  margin-right: 18px;
}

.footer-copyright-text {
  font-size: 16px;
  color: #737373;
}

.footer-additional-text {
  font-size: 16px;
  color: #737373;
  text-align: right;
  float: right;
}

.footer-additional-text a,
.footer-additional-text a:hover,
.footer-additional-text a:visited {
  color: inherit;
  text-decoration: none;
}

.clickable {
  cursor: pointer;
}

input {
  color: #63686b;
}

::placeholder {
  color: #aaa;
}

.soon {
  position: relative;
}

.soon > img {
  position: absolute;
  left: -9px;
  bottom: 10px;
}

.soon > span {
  position: absolute;
  color: white;
  width: 204px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  vertical-align: middle;
  font-size: 19px !important;
  font-weight: 600;
  left: -9px;
  bottom: 15px;
}
/* End of Footer CSS */
