/* End Lesson - Congratulations Area */
.end-lesson-container {
  background-image: url('../../../images/finish_violet.svg');
  height: 100%;
}

.end-lesson-div {
  text-align: center;
  width: 318px;
  margin: 110px auto 0 auto;
  user-select: none;
}

.end-lesson-lbl {
  font-size: 25px;
  line-height: 30px;
  text-align: center;
  color: #494947;
}

.end-lesson-img {
  height: 302px;
  width: 302px;
  text-align: center;
  margin: 40px 0 15px 0;
  transition: scale 0.3s;
  animation: 1.1s shakeImage;
}

@keyframes shakeImage {
  0%,
  100% {
    transform: scale(1);
  }

  25% {
    transform: scale(1.1);
  }

  50% {
    transform: scale(0.8);
  }

  75% {
    transform: scale(1.1);
  }
}

.el-progress-container {
  margin: 16px auto 14px auto;
  height: 10px;
  width: 200px;
}

.el-progress {
  height: 14px;
  background: linear-gradient(#ffd14c 50%, #ffa00a 50%);
  z-index: 2;
}

.next-lesson-btn {
  height: 50px;
  width: 295px;
  background: #683da1;
  border-radius: 25px;
  font-size: 19px;
  line-height: 23px;
  color: #ffffff;
  text-align: center;
  margin: 35px 0 20px 0;
  border: none !important;
  outline: 0 !important;
}

.return-lnk {
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 20px;
  color: #9575cd;
  text-align: center;
  cursor: pointer;
}

.modal-btn-remove-ads {
  width: 275px;
  height: 45px;
  margin-right: 20px;
  background-color: #ffa00a;
  color: white;
  text-transform: uppercase;
  margin: auto auto 18px auto;
  font-family: Lato;
  font-size: 19px;
  font-weight: 500;
  border-radius: 5px;
  outline: 0 !important;
}

.modal-btn-remove-ads:hover {
  cursor: pointer;
  color: white;
}

/* Successful Completion */
.sc-container {
  background-image: url('../../../images/finish_blue.svg');
  height: 100%;
}

.sc-img {
  height: 223px;
  width: 252px;
}

.sc-lbl {
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  color: #494947;
  margin: 25px auto 15px auto;
}

.sc-btn {
  height: 50px;
  width: 295px;
  background: #eb1f7f;
  border-radius: 25px;
  font-size: 19px;
  line-height: 23px;
  color: #ffffff;
  text-align: center;
  margin-top: 78px;
  border: none !important;
  outline: 0 !important;
}

/* Unsuccessful Page */
.usc-container {
  background-image: url('../../../images/finish_pink.svg');
  height: 100%;
}

.usc-img {
  width: 180px;
  height: 223px;
}

.usc-msg {
  font-size: 19px;
  line-height: 27px;
  color: #737373;
}

.usc-btn {
  margin-top: 50px !important;
  border: none !important;
  outline: 0 !important;
}

/* Offer Page */
.op-container {
  background-image: url('../../../images/finish_yellow.svg');
  height: 100%;
}

.op-btn {
  margin-bottom: 20px;
  outline: 0 !important;
}

.op-img {
  height: 262px;
  width: 302px;
}

.op-nt {
  margin-top: 20px;
  font-size: 17px;
  line-height: 20px;
  color: #eb1f7f;
  cursor: pointer;
}
