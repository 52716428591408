/* common.css */

.info-layout {
  margin: 30px auto 55px auto;
}

.info-accord {
  width: 1110px;
}

.info-header {
  background: #fffffd;
  border: none;
  border-radius: 10px 10px 0px 0px;
  height: 95px;
  /* line-height: 95px; */
  vertical-align: middle;
  cursor: pointer;
  padding: 35px 35px 0 35px;
  font-size: 19px;
  color: #494947;

  -webkit-box-shadow: 0 12px 20px -5px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: 0 12px 20px -5px rgba(0, 0, 0, 0.09);
  box-shadow: 0 12px 20px -5px rgba(0, 0, 0, 0.09);
}

.info-body {
  /* height: 615px; */
  padding: 35px 35px 0 35px;
  overflow-y: auto;
}

.info-body::-webkit-scrollbar {
  width: 5px;
}

.info-body::-webkit-scrollbar-track {
  background-color: inherit;
}

.info-body::-webkit-scrollbar-thumb {
  border-radius: 5.5px;
  background-color: #e4e4e1;
}

.info-body-text {
  color: #63686b;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 20px;
  letter-spacing: -0.222083px;
}

.info-body-text .subtitle {
  font-weight: 700;
  color: #f49500;
}
