.modal-buy-subscription > .modal-dialog > .modal-content {
  height: auto !important;
  padding: 40px !important;
}

.modal-buy-subscription > .modal-dialog > .modal-content > .modal-header {
  margin-right: 0 !important;
  margin-bottom: 15px !important;
}

.modal-buy-subscription > .modal-dialog > .modal-content > .modal-body {
  padding: 0 !important;
}

.modal-buy-subscription form {
  padding-bottom: 0 !important;
  border-bottom: none !important;
}

.modal-buy-subscription form > div {
  margin: 25px auto 0 auto !important;
  float: right;
}

.modal-buy-subscription .Checkout {
  margin: 0 auto;
  max-width: 800px;
  box-sizing: border-box;
  padding: 0 5px;
}

.modal-buy-subscription label {
  color: #6b7c93;
  font-weight: 300;
  letter-spacing: 0.025em;
  display: block;
}

.modal-buy-subscription .error-message {
  color: #ee2222;
}

.modal-buy-subscription .half-field {
  width: 45%;
  display: inline-block;
}

.modal-buy-subscription .right {
  margin-left: 10%;
}

.modal-buy-subscription .Loader {
  margin-top: 0px;
  margin-bottom: 8px;
}

.modal-buy-subscription form {
  margin-bottom: 40px;
  padding-bottom: 40px;
  border-bottom: 3px solid #e6ebf1;
}

.modal-buy-subscription input,
.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  max-width: 500px;
  padding: 10px 14px;
  font-size: 1em;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white;
}

.modal-buy-subscription input::placeholder {
  color: #aab7c4;
}

.modal-buy-subscription input:focus,
.StripeElement--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.modal-buy-subscription .StripeElement.IdealBankElement,
.modal-buy-subscription .StripeElement.PaymentRequestButton {
  padding: 0;
}
