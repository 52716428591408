.lesson-c-img,
.lesson-a-img,
.lesson-n-img,
.lesson-c-img,
.rules-img {
  cursor: pointer;
}

.exercise {
  background: #f2f2f2;
  margin: auto;
}

.exercise-wrapper {
  max-height: 510px;
  max-width: 500px;
  margin: auto;
  overflow: hidden;
}

.exercise-header {
  width: 1110px;
  margin: 25px auto 0px auto;
}

.ex-header-close {
  display: inline-block;
  cursor: pointer;
}

.ex-header-info {
  float: right;
  cursor: pointer;
}

.ex-header-progress {
  display: inline-block;
  width: 920px !important;
  height: 10px;
  line-height: 10px;
  background: #e4e4e1;
  z-index: -2;
  border-radius: 5px;
  overflow: visible;
  margin-left: 70px;
}

.ex-bg-warning {
  color: #ffa00a;
  background: linear-gradient(#ffd14c 50%, #ffa00a 50%);
  border-radius: 6px;
  max-width: 920px !important;
  height: 14px;
  z-index: 2;
  position: relative;

  box-shadow: 0px 3px 10px rgba(227, 226, 232, 0.5),
    0px -3px 10px rgba(227, 226, 232, 0.5);
}

.exercise-body-wrapper {
  height: 510px;
  width: 500px;
}

.exercisebody {
  height: 510px;
  width: 500px;
  position: absolute;
}

.exercisebody-placeholder {
  height: 510px;
  width: 500px;
  position: relative;
}

.exercisebody > h5 {
  text-align: center;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  color: #494947;
}

/* Exercise cards - containers */
.exercise-card {
  margin: 68px auto 98px auto;
  padding-top: 47px;
  background: #fffffd;
  border-radius: 10px;
  width: 335px;
  height: 360px;
  -webkit-box-shadow: 0 20px 20px -20px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 20px 20px -20px rgba(0, 0, 0, 0.1);
  box-shadow: 0 20px 20px -20px rgba(0, 0, 0, 0.1);
}

.exercise-card-type3 {
  margin: 68px auto 98px auto;
  height: 410px;
  width: 360px;
}

.exercise-card-type4,
.exercise-card-type5 {
  margin: 68px auto 98px auto;
  height: 410px;
  width: 360px;
}

.exercise-card-type6,
.exercise-card-type8,
.exercise-card-type11,
.exercise-card-type12,
.exercise-card-type14 {
  margin: auto;
  width: 435px;
}

.exercise-card-type7 {
  /* height: 245px; */
  /* width: auto; */
  margin: 82px auto;
}

.exercise-card-type7 > p:first-child {
  font-size: 22px;
  line-height: 26px;
}

.exercise-card-type7 > p:nth-child(2) {
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 23px;
  color: #737373;
}

.exercise-card-type7 > img {
  margin-top: 179px;
  height: 145px;
  width: 145px;
  border-radius: 50%;
  cursor: pointer;
}
.exercise-card-type7 .exercise-soundcard {
  padding: 6px;
  background-color: #f4f3f9;
}

.exercise-card-type7 > img:hover {
  -webkit-filter: brightness(97%);
  filter: brightness(97%);
}

.exercise-card-type8 > p:first-child {
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 23px;
  color: #737373;
  margin-top: 66px;
  text-align: center;
}

.exercise-card-type8 > p:nth-child(2) {
  font-size: 22px;
  line-height: 26px;
  color: #494947;
  margin-bottom: 70px;
  text-align: center;
}

.exercise-card-type9 {
  width: 400px;
  margin: 70px auto 70px auto;
}

.exercise-card-type9 > p:first-child {
  text-align: center;
  margin-bottom: 70px;
}

.exercise-card-type10 {
  text-align: center;
  width: 340px;
  margin: 70px auto 70px auto;
}

.exercise-card-type11 {
  text-align: center;
  margin: 151px auto 104px auto;
}

.exercise-card-type13 {
  text-align: center;
  margin: 67px auto 104px auto;
}

.exercise-card-type13 > p:first-child {
  margin-top: 67px;
  margin-bottom: 95px;
}

.ex-text-area11,
.ex-text-area13 {
  border: 0;
  background-color: none;
  background: none;
  background-attachment: local;
  line-height: 5ch;
  background-image: linear-gradient(
    transparent,
    transparent calc(5ch - 1px),
    #afafaf 0px
  );
  background-size: 100% 5ch;
  padding: 8px 10px;
  width: 400px;
  height: 190px;
  resize: none;
  margin-bottom: 104px;
  outline: none;
  overflow: hidden;
  font-size: 19px;
}

.ex-help-link {
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  color: #9575cd;
  cursor: pointer;
  user-select: none;
}

.exercise-card-type12 > p:first-child,
.exercise-card-type14 > p:first-child {
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 23px;
  color: #737373;
  text-align: center;
  margin: 70px auto 107px auto;
}

.exercise-subcard-type14 .exercise-soundcard {
  padding: 6px;
  background-color: #f4f3f9;
}

.exercise-card-type14 > p:first-child {
  margin-bottom: 80px !important;
}

.exercise-card-type14 > p:last-child {
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 20px;
  color: #9575cd;
  cursor: pointer;
  text-align: center;
}

/* Exercise card container end */

/* type 3, type 4, type 5 */
.type3_label:nth-child(2),
.type3_label:last-child,
.type4_label:nth-child(2),
.type4_label:last-child,
.type5_label:nth-child(2),
.type5_label:last-child {
  margin-right: 0;
}

.exercise-type-divider {
  border: 1px solid #e4e4e1;
  margin: 0 0 20px 0;
}

/* Subcards area, smaller cards inside exercise containers */
/* type 1 & type 2 */
.exercise-subcard {
  height: 154px;
  width: 145px;
  margin: auto;
}

/* type 3 */
.ex-subcard-type3 {
  display: inline-block;
  background: #fffffd;
  width: 158px;
  border-radius: 10px;
  height: 195px;
  margin: 0 20px 20px 0;
  cursor: pointer;
}

/* type 3 image */
.ex-subcard-type3 > img {
  margin: 30px 25px 24px 25px;
  height: 97px;
  width: 100px;
}

.ex-subcard-type3 > p {
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 23px;
  color: #494947;
}

/* type 3,4,5 radio button */
.type3_option,
.type4_option,
.type5_option {
  display: none;
  margin: 0 20px 20px 0;
}

/* type 3,4,5 radio button check state */
.type3_option:checked + .ex-subcard-type3,
.type4_option:checked + .ex-subcard-type4,
.type5_option:checked + .ex-subcard-type5 {
  box-shadow: 0 0 0px 2px #ffd14c, 0 20px 20px -20px rgba(0, 0, 0, 0.1);
}

/* type 4 */
.ex-subcard-type4,
.ex-subcard-type5 {
  display: inline-block;
  background: #fffffd;
  width: 158px;
  height: 158px;
  border-radius: 10px;
  margin: 0 20px 20px 0; /* margin for sub cards on type 3 */
  cursor: pointer;
}

.ex-subcard-type5 {
  position: relative;
}

/* type 4 image */
.ex-subcard-type4 > img {
  margin: 30px 25px 25px 25px;
  height: 97px;
  width: 100px;
}

/* type 5 text */
.type5_translation {
  position: absolute;
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  color: #494947;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

/* type 6 audio */
.type6-audio-div {
  margin: 83px auto 45px auto;
}

.exercise-card-type6 .exercise-soundcard {
  padding: 4px;
  background-color: #f4f3f9;
}

.type6-audio-div > .exercise-soundcard > img:hover {
  -webkit-filter: brightness(97%);
  filter: brightness(97%);
}

/* type 6 radio button label */
.type6_label {
  height: 46px;
  width: 435px;
  display: inline-block;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  color: #494947;
  margin: 0;
  cursor: pointer;
}

/* type 6 radio button */
.type6_option,
.type12_option {
  height: 22px;
  width: 22px;
  margin: 0 20px 0 0;
  cursor: pointer;
}

.exercise-card-type7 .exercise-soundcard {
  margin-top: 178px;
}

.exercise-subcard > img {
  height: 154px;
  width: 145px;
}

.exercise-subcard-label {
  margin: 62px auto 25px auto;
}

.ex-2-separator {
  height: 20px;
  width: 20px;
  margin: 0 10px 0 10px;
}

.exercise-subcard-label > p,
.exercise-card > p {
  display: inline-block;
  align-content: center;
}

.exercise-soundcard {
  margin: auto;
  border-radius: 50%;
  padding: 10px;
  background: #fffffd;
  box-shadow: 0px 3px 10px rgba(227, 226, 232, 0.5),
    0px -3px 10px rgba(227, 226, 232, 0.5);
  display: table;
  cursor: pointer !important;
}

.exercise-soundcard > img {
  margin: auto;
}

.exercise-soundcard:hover {
  -webkit-filter: brightness(97%);
  filter: brightness(97%);
}

.speach_round {
  margin-right: 10px;
  cursor: pointer;
}

.speach_round:hover {
  background: #ffeff6;
  border-radius: 50%;
}

.exercise-subcard-type14 {
  text-align: center;
  font-size: 17px;
  line-height: 20px;
  color: #737373;
  margin-bottom: 35px;
}

.ex-options-type9 {
  background-attachment: local;
  line-height: 6ch;
  background-image: linear-gradient(
    transparent,
    transparent calc(6ch - 1px),
    #afafaf 0px
  );
  background-size: 100% 6ch;
  height: 170px;
  margin-bottom: 55px;
}

.ex-options {
  margin-top: 70px;
  margin-bottom: 100px;
}

.ex-sentence {
  padding: 10px 0 10px 0;
  max-width: 400px;
}

.ex-options-btn {
  background: #fffffd;
  border-radius: 7px;
  padding: 6px 14px 11px 14px;
  margin: 10px 10px 0 10px;
  border: 1px;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 23px;
  color: #494947;
}

.ex-options-btn:disabled {
  background: #e2e8f6;
  color: #e2e8f6;
}

.option-hidden,
.option-hidden:hover {
  background: #ffe8a5 !important;
  color: #ffe8a5 !important;
  cursor: unset !important;
  outline: none !important;
  box-shadow: none !important;
}

.ex-sentence-hidden {
  height: 40px;
  width: 66px;
  background: #e2e8f6;
  border-radius: 7px;
  padding: 6px 14px 11px 14px;
  margin: 10px 10px 0 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 23px;
  color: #e2e8f6;
  border: 1px;
}

.ex-sentence-btn {
  background: #fffffd;
  border-radius: 7px;
  padding: 6px 14px 11px 14px;
  margin: 10px 10px 0 10px;

  border: 1px;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 23px;
  color: #494947;
}

.ex-sentence-btn:hover,
.ex-sentence-hidden:hover {
  outline: none;
  box-shadow: none;
}

.type14-img {
  height: 145px;
  width: 145px;
  cursor: pointer;
  border-radius: 50%;
}

.type14-img:hover {
  -webkit-filter: brightness(97%);
  filter: brightness(97%);
}

/* End subcard areas for all types */

/* default footer area */
.exercise-footer {
  display: inline-block;
  height: 138px;
  bottom: 0;
  padding: 44px 0px 44px 0px;
  background: #fffffd;
  user-select: none;
  z-index: 1;
}

.exercise-footer-content {
  width: 1110px;
  margin: 0px auto 0px auto;
}

.exercise-footer-content > p {
  display: inline-block;
  height: 50px;
  line-height: 50px;
  font-style: normal;
  font-weight: 300;
  font-size: 17px;
  cursor: pointer;
  color: #9575cd;
  user-select: none;
}

.ex-btn-footer-div {
  float: right;
}

.ex-notproperly-btn,
.ex-notproperly-btn:hover {
  display: inline-block;
  width: 255px;
  height: 50px;
  background: #eb1f7f;
  border-radius: 25px;
  font-size: 19px;
  color: #ffffff;
  margin-right: 25px;
}

.ex-further-btn,
.ex-further-btn:hover {
  width: 255px;
  height: 50px;
  background: #683da1;
  border-radius: 25px;
  font-size: 19px;
  color: #ffffff;
  float: right;
}

/* end default footer */

/* section for correct footer area  */
.footer-correct {
  background: #81c784;
  color: #388e3c;
  position: relative;
}

.footer-correct-message {
  float: left;
  color: #388e3c;
}

.footer-correct-message > p:nth-child(2) {
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 22px;
  letter-spacing: -0.528px;
}

.correct-ic {
  margin-top: -180px;
  float: left;
  position: absolute;
  height: 176px;
  width: 128px;
}

.correct-point {
  position: absolute;
  margin-top: -100px;
  margin-left: 150px;
  font-size: 19px;
  line-height: 23px;
  color: #81c784 !important;
  cursor: default !important;
}

.footer-btn-div {
  float: right;
  color: #ffffff;
  height: auto;
}

.footer-btn-div > p {
  cursor: pointer;
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 0px;
}

.continue-btn,
.continue-btn:hover {
  margin-bottom: 13px;
  border-radius: 25px;
  color: #ffffff;
  width: 255px;
  height: 50px;
  font-size: 19px;
  font-weight: 900;
}

.c-btn {
  background: #388e3c;
}

/* end correct footer area */

/* incorrect footer area */
.footer-incorrect {
  background: #e57373;
  color: #c62828;
  position: relative;
}

.footer-incorrect-message {
  float: left;
  color: #c62828;
}

.footer-incorrect-message > p:first-child {
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 16px;
  letter-spacing: -0.132px;
}

.inc-btn {
  background: #c62828;
}

.incorrect-point {
  position: absolute;
  margin-top: -100px;
  margin-left: 150px;
  font-size: 19px;
  line-height: 23px;
  color: #e57373 !important;
  cursor: default !important;
}
/* end incorrect footer */

/* Topic Rules Modal */
.modal-exercise > .modal-dialog > .modal-content {
  min-width: 600px !important;
  min-height: 600px !important;
  max-height: 640px !important;
  background: #ffffff;
  box-shadow: 0px 1px 1px rgba(118, 118, 118, 0.5);
  border-radius: 5px;
  padding: 25px 0 0 30px;
  position: relative;
}

.modal-header-exercise {
  width: 400px;
  /* height: 50px; */
  width: auto;
  padding: 0;
  top: 0;
  display: block;
}
.modal-header-content {
  height: 50px;
}

.ex-modal-close {
  height: 20px;
  width: 20px;
  margin-right: 20px;
}

.modal-header-content > label {
  font-size: 19px;
  font-weight: 900;
  line-height: 23px;
  letter-spacing: 1.00067px;
  color: #202528;
}

.modal-body-exercise {
  padding: 35px 0 0 0;
  overflow-x: auto;
}

.no-scroll {
  overflow: hidden !important;
}

.exercise-rules-wrapper {
  width: 540px;
  height: 100%;
  overflow: hidden !important;
}

.exercise-rules-wrapper-inner {
  width: 570px;
  height: 99%;
  overflow-y: auto;
}
.exercise-rules-wrapper-inner body {
  padding-right: 19px;
  padding-bottom: 20px;
}

/* Exit Modal */
.modal-exercise-exit > .modal-dialog > .modal-content {
  padding: 25px 30px 25px 30px;
  width: 600px;
  height: auto;
}

.mb-exit > p {
  color: #63686b;
  margin-bottom: 55px;
}

/* .modal-body-exercise::-webkit-scrollbar {
  width: 5px;
}

.modal-body-exercise::-webkit-scrollbar-track {
  width: 5px;
}

.modal-body-exercise::-webkit-scrollbar-thumb {
  background: #e4e4e1;
  border-radius: 5.5px;
} */

.modal-body-exercise html,
body {
  min-width: auto !important;
  background: none !important;
}

.modal-body-exercise html p,
span {
  font-size: 100% !important;
}

.modal-body-exercise html table {
  font-size: 90% !important;
}

.ex-modal-close {
  cursor: pointer;
}

.slide-enter {
  opacity: 0;
  transform: translateX(1000px);
}

.slide-enter.slide-enter-active {
  opacity: 1;
  transform: translateX(0px);
  transition: opacity 200ms linear 300ms, transform 500ms ease-in-out;
}

.slide-exit {
  opacity: 1;
  transform: translateX(0px);
}

.slide-exit.slide-exit-active {
  opacity: 0;
  transform: translateX(-1000px);
  transition: opacity 200ms linear, transform 500ms ease-in-out;
}

/* Report Error Modal */
.report-modal > .modal-dialog > .modal-content {
  width: 600px;
  height: 514px;
}

.modal-report-label {
  display: inline-block;
  width: 510px;
  height: 56px;
  margin-bottom: 20px;
}

.modal-report-label:first-child {
  margin-top: 15px;
}

.modal-report-label:nth-last-child(2) {
  margin-bottom: 55px;
}

.report-cb-div {
  float: left;
  height: 19px;
  line-height: 19px;
  vertical-align: middle;
  width: 19px;
  margin: 3px 15px 20px 0;
  cursor: pointer;
}

.report-cb-div > input[type='checkbox'] {
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  background: #ffff;
  box-sizing: border-box;
  position: relative;
  box-sizing: content-box;
  height: 15px;
  width: 15px;
}

.report-cb-div > input[type='checkbox']:checked {
  width: 10px;
  height: 10px;
  background: #f29400;
  border-radius: 1px;
}

.report-cb-div > input[type='checkbox']:focus {
  outline: 0 none;
  box-shadow: none;
}

.cb-label-div {
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 28px;
  letter-spacing: -0.456px;
  color: #63686b;
  cursor: pointer;
}

.div-radio {
  cursor: pointer;
}

.modal-confirm-body {
  padding: 33px 0 0 0 !important;
}

.modal-confirm-body > p {
  margin-bottom: 30px;
}

.type6_audio:active {
  content: url('../../../images/play_btn_small_pressed.svg');
  /* background-image: url('../../../images/play_btn_big_pressed.svg');  */
}
.type7_audio:active {
  content: url('../../../images/play_btn_big_pressed.svg');
  /* background-image: url('../../../images/play_btn_big_pressed.svg');  */
}

.type14-img:active {
  content: url('../../../images/mic_btn_pressed.svg');
}

.type14-recognized {
  text-align: center;
  font-family: LatoWeb;
  font-size: 19px;
  color: #494947;
  padding-bottom: 60px;
  height: 88px;
}

.ex-preload-images {
  position: absolute;
  top: -100px;
  left: -100px;
  width: 10px;
  height: 10px;
  background: url('../../../images/play_btn_small_pressed.svg'),
    url('../../../images/play_btn_big_pressed.svg'),
    url('../../../images/mic_btn_pressed.svg'),
    url('../../../images/correct_ic.svg'),
    url('../../../images/incorrect_ic.svg');
}

.end-lesson-div label b {
  color: #f29400;
}

@media only screen and (max-height: 720px) {
  .exercise-header {
    margin: 15px auto 0px auto;
  }
  .exercise-wrapper {
    margin-top: -25px;
    transform: scale(0.8);
  }
  .exercise-footer {
    transform: scaleY(0.8);
    transform-origin: bottom;
    margin-top: -50px;
  }
  .exercise-footer-content {
    transform: scaleY(1.25);
  }
  .exercise-card-type3,
  .exercise-card-type4,
  .exercise-card-type5 {
    margin: 40px auto 18px auto;
  }
  .ex-subcard-type3 > img {
    margin: 20px 25px 24px 25px;
  }
  .continue-btn,
  .continue-btn:hover {
    margin-bottom: 8px;
  }
  .footer-correct-message {
    margin-top: -8px;
  }
  .footer-btn-div {
    margin-top: -6px;
  }
  .exercise-card-type7 .exercise-soundcard {
    margin-top: 140px;
  }
  .exercise-card-type9 {
    margin: 40px auto 40px auto;
  }
  .ex-options-type9 {
    margin-bottom: 25px;
  }
  .exercise-card-type11 {
    margin: 80px auto 0px;
  }
  .exercise-card-type13 {
    margin: 20px auto 0px;
  }
  .ex-text-area11,
  .ex-text-area13 {
    margin-bottom: 40px;
  }
  .exercise-card-type13 > p:first-child {
    margin-top: 40px;
    margin-bottom: 50px;
  }
  .exercise-card-type12 > p:first-child,
  .exercise-card-type14 > p:first-child {
    margin: 40px auto 80px auto;
  }
  .exercise-card-type14 > p:first-child {
    margin-bottom: 40px !important;
  }
  .type14-recognized {
    height: 68px;
  }
  .end-lesson-container,
  .sc-container,
  .usc-container,
  .op-container {
    transform: scale(0.8);
  }
  .end-lesson-div {
    margin: 10px auto 0 auto;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .no-scroll {
    overflow-x: hidden;
    overflow-y: auto !important;
  }
}
