/* dashboard.css */

#theme-accord:after {
  display: none;
}

.panel-layout {
  margin: 30px auto 50px auto;
  /* display: flex; */
}

/* Left Panel */
.left-panel {
  width: 730px;
  margin: 0 30px 0 0;
  /* added float instead of display flex in panel-layout */
  float: left;
}

.theme-img {
  height: 58px;
  width: 59px;
}

.lock-img {
  position: absolute;
  bottom: 17px;
  height: 35px;
  width: 27px;
  left: 50%;
  margin-left: -13px;
}

.lesson-c-img:hover {
  content: url(../../images/lesson_complete_hover_ic.svg);
}

.lesson-a-img:hover {
  content: url(../../images/lesson_available_hover_ic.svg);
}

.rules-img:hover {
  content: url(../../images/rules_hover_ic.svg);
}

.lesson-non-c-img:hover {
  content: url(../../images/extra_words_hover_ic.svg);
}

.theme-progress {
  height: 33px;
  width: 33px;
}

.theme-img-icon {
  display: block;
  margin-left: 2%;
  width: 3.625em;
  height: 3.688em;
  background-color: #fffffe;
}

.theme-card-text {
  flex: 1;
  max-width: 440px;
  max-height: 75px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 0.875em;
  line-height: 1.5em;
  color: #494947;
  font-size: 19px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  word-wrap: break-word;
}

.theme-right-icon {
  display: block;
  /* float: right;
  line-height: 4.18em; */
}

.theme-progress-div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.theme-progress-div > span {
  display: block;
  /* margin-top: 8%; */
}

.theme-progress-bar {
  /* margin-top: 20%;
  float: right; */
  margin-right: 15px;
  margin-left: 15px;
  color: #ffd14c;
  background-color: #e4e4e1;
  border-radius: 7px;
  width: 100px;
  height: 13px;
}

.theme-body {
  /* display: inline-block; */
  /* position: relative; */
  height: 220px;
  width: 730px;
  /* overflow-x: hidden; */
  overflow-x: scroll;
  overflow-y: hidden;
  /* overflow-x: hidden; */
  /* overflow: scroll; */
  /* overflow: hidden; */
  white-space: nowrap;
}

.theme-body:hover {
  overflow-x: scroll;
}

.theme-body::-webkit-scrollbar-thumb {
  background-color: #e4e4e1;
  /* border: 2.5px solid #e4e4e1;*/
  /* border: white solid;
  border-radius: 50px; */
  height: 5px;
  padding: 5px;
}

.theme-body::-webkit-scrollbar-track {
  height: 5px;
}

.theme-body::-webkit-scrollbar {
  height: 5px;
  /* display: none;
  background: transparent;
  width: 0px; */
}

.theme-item {
  width: 125px;
  height: 160px;
  margin-right: 25px;
  display: inline-block;
}

.theme-item * {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.theme-item span {
  text-overflow: ellipsis;
  max-width: 125px;
  overflow: hidden;
  display: block;
}

.theme-item:first-child {
  margin-left: 9%;
}

.theme-item-img {
  width: 125px;
  height: 125px;
  border-radius: 50px;
  cursor: pointer;
}

.popover {
  /* top: 160px !important; */
  width: 375px;
  max-width: 375px !important;
  height: 230px;
  background-color: #494947;
  border-radius: 10px;
  text-align: center;
}

.popover-divider {
  position: absolute;
  bottom: 48px;
  background-color: #fffffd;
  width: 294px;
  left: 39px;
}

.popover-body {
  color: #fffffd;
  font-size: 17px;
  margin: auto 45px auto 45px;
  padding: 0px;
}

.popover-body h4 {
  font-size: 19px;
  font-weight: 500;
  margin-top: 20px;
  margin-bottom: 20px;
}

.popover-body p {
  position: absolute;
  width: 285px;
  bottom: 6px;
}

.bs-popover-auto[x-placement^='bottom'],
.bs-popover-bottom > .arrow::after {
  border-bottom-color: #494947;
}

.bs-popover-auto[x-placement^='top'],
.bs-popover-top > .arrow::after {
  border-top-color: #494947;
}

/* Temporarily disabled due to client request  
Commented Date: November 8, 2019 */
/*.popover-link {
  cursor: pointer;  
} 

.popover-link:hover {
  text-decoration: underline;
  color: #ccc; 
}
*/

.accordion-btn {
  padding: 0;
}

.accordion > .card:last-of-type {
  border-radius: 10px;
}

.card-header {
  border-radius: 10px 10px 0 0;
}

.cd-left-layout {
  width: 730px;
  background-color: #fffffd;
  border: 2px solid;
}

.cd-layout-body {
  height: 220px;
  border-radius: 10px;
}

/* left panel - user ratings */
.user-rating-card {
  display: block;
  height: 76px;
  line-height: 55px;
  /* vertical-align: middle; */
  padding: 10px 30px 10px 16px;
  width: 730px;
  background-color: #fffffd;
  border-radius: 10px;
  margin-bottom: 15px;
}

.rating-avatar {
  vertical-align: middle;
  line-height: 0px;
}

.rating-table-text {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #afafaf;
}

.rating-rank {
  color: #afafaf;
}

.rating-img {
  height: 56px;
  width: 56px;
}

.rating-username {
  font-size: 18px;
  color: #494947;
}

.rating-points {
  float: right;
  font-size: 15px;
}

.rating-points > span {
  color: #f29400;
}

/* left panel - user status */
.status-header {
  background: #fffffd;
  border: none;
  height: 95px;
  line-height: 95px;
  vertical-align: middle;
  padding-left: 35px;
  border-radius: 10px 10px 0 0;
  -webkit-box-shadow: 0 12px 20px -5px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: 0 12px 20px -5px rgba(0, 0, 0, 0.09);
  box-shadow: 0 12px 20px -5px rgba(0, 0, 0, 0.09);
}

.status-header > img {
  margin-right: 30px;
  cursor: pointer;
}

.status-header > span {
  font-size: 19px;
  color: #494947;
}

.card-body .status-body {
  padding: 0px;
}

.status-body {
  padding: 0px 35px !important;
  width: 730px;
  overflow: auto;
}

.status-list-left,
.status-list-center,
.status-list-right {
  display: inline-block;
}

.status-list-left {
  width: 162px;
  max-height: 1554px;
  float: left;
  padding-top: 123px;
  overflow: hidden;
}

.status-list-center {
  width: 335px;
  max-height: 1554px;
  position: relative;
  /* overflow: hidden; */
}

.status-list-right {
  width: 145px;
  max-height: 1554px;
  float: right;
  padding-top: 72px;
  overflow: hidden;
}

.status-list-right > img,
.status-list-left > img {
  display: block;
  margin: 0 auto 124px auto;
}

.status-img-div {
  padding: 5px 5px 5px 5px;
  border-radius: 50px;
  display: inline-block;
  position: absolute;
  width: 305px;
  line-height: 50px;
  max-height: 70px;
  overflow: visible;
}

.status-title,
.status-point,
.status-point-lbl {
  font-size: 17px;
  color: #494947;
}

.status-point {
  color: #989898;
}

.status-point.passed {
  color: #f29400;
}

.tree_05 {
  height: 153px;
  width: 67px;
}

.tree_03 {
  height: 118px;
  width: 102px;
}

.tree_04 {
  height: 128px;
  width: 64px;
}

.tree_02 {
  height: 28px;
  width: 55px;
}

.tree_01 {
  height: 123px;
  width: 54px;
}

.road-img {
  /* margin-top: -35px; */
  margin-bottom: 0px;
}

.road-img-progress {
  position: absolute;
  top: 0px;
  left: 0px;
  /* margin-top: -35px; */
  margin-bottom: 0px;
}

.status-avatar-img {
  height: 70px;
  width: 70px;
}

/* End Left Panel */

/* 
==========================    
    Right Panel 
==========================
*/

.right-panel {
  /* display: inline-block; */
  /* added float instead of display flex in panel-layout and removed inline-block commented above */
  float: right;
}

.right-info-top {
  width: 350px;
  height: 141px;
  border-radius: 10px;
  vertical-align: middle;
  background-color: #fffffd;
  margin-bottom: 1.875em;
}

.user-status-img {
  height: 70px;
  width: 70px;
  border-radius: 50%;
}

.right-info-wrapper {
  padding: 35px 25px 35px 20px;
  cursor: pointer;

  -webkit-box-shadow: 0 20px 20px -20px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 20px 20px -20px rgba(0, 0, 0, 0.1);
  box-shadow: 0 20px 20px -20px rgba(0, 0, 0, 0.1);
}

.shadow-1 {
  -webkit-box-shadow: 0 20px 20px -20px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 20px 20px -20px rgba(0, 0, 0, 0.1);
  box-shadow: 0 20px 20px -20px rgba(0, 0, 0, 0.1);
}

.right-info-img,
.right-info-text {
  display: inline-block;
}

.right-info-img {
  width: 70px;
  height: 70px;
  line-height: 70px;
  vertical-align: middle;
}

.right-info-text {
  width: 217px;
  height: 70px;
  line-height: 70px;
  vertical-align: middle;
  margin-left: 15px;
}

.right-info-maintext {
  font-style: normal;
  font-weight: bold;
  font-size: 19px;
  line-height: 23px;
  color: #494947;
  margin: 9px 0 8px 0;
}

.right-info-subtext {
  font-size: 13px;
  line-height: 16px;
  color: #afafaf;
}

.right-info-bottom {
  width: 350px;
  height: 539px;
  border-radius: 10px;
  background-color: #fffffd;
  margin-bottom: 7.5em;
  padding: 35px 25px 0 26px;

  -webkit-box-shadow: 0 20px 20px -20px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 20px 20px -20px rgba(0, 0, 0, 0.1);
  box-shadow: 0 20px 20px -20px rgba(0, 0, 0, 0.1);
}

.right-bottom-header {
  width: 298px;
  height: 143px;
}

.right-bottom-header > h5 {
  margin-bottom: 35px;
}

.rounded-circle {
  display: inline-block;
  width: 38px;
  height: 38px;
  line-height: 38px;
  text-align: center;
  background-color: #eeeeee;
  margin-right: 5px;
}

.marked-days {
  background-color: #ffd14c;
  color: #fffffd;
}

.rounded-circle:last-child {
  margin-right: 0px !important;
}

.right-divider {
  margin: 36px 0 35px 0;
  border: solid 0.5px #e4e4e1;
}

.right-bottom-body {
  width: 266px;
  height: 239px;
}

.right-bottom-body-texts {
  font-size: 17px;
  color: #494947;
}

.right-bottom-header {
  margin-bottom: 5px;
}

.vertical-menu {
  width: 350px;
  background-color: #fffffd;
  border-radius: 10px;
  padding: 17px 0 17px 0;
}

.vertical-menu span {
  display: block;
  padding: 8px 0 12px 35.5px;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
}

.vertical-menu span:hover {
  color: #f29400;
}

.vertical-menu span.active {
  background-color: #eeeeee;
  color: #f29400;
}

.refund-policy {
  color: #f29400 !important;
  text-decoration: underline;
  cursor: pointer;
  user-select: none;
  height: auto !important;
  margin-bottom: 10px;
}

.general-header {
  background: #fffffd;
  border: none;
  height: 95px;
  line-height: 95px;
  vertical-align: middle;
  padding-left: 35px;
  border-radius: 10px 10px 0 0;
  -webkit-box-shadow: 0 12px 20px -5px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: 0 12px 20px -5px rgba(0, 0, 0, 0.09);
  box-shadow: 0 12px 20px -5px rgba(0, 0, 0, 0.09);

  font-family: LatoWeb;
  font-size: 19px;
  font-weight: 600;
  color: #494947;
}

.general-info {
  height: 940px;
  margin-bottom: 55px;
}

.general-divider {
  margin: 17px 0px 0 0px;
  border: solid 1px #e4e4e1;
}

.general-info-body {
  padding: 55px 35px 55px 35px;
  color: #63686b;
}

.general-info-body > h5 {
  color: #63686b;
  letter-spacing: 1px;
  font-weight: 600;
}

.general-info-body label {
  width: 110px;
  margin: 0 20px 40px 0;
}

.general-username {
  padding: 0 0 12px 0;
  line-height: 60px;
}

.general-pass {
  padding-top: 30px;
  padding-bottom: 30px;
}

.general-reset-div {
  font-family: LatoWeb;
  font-size: 19px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: 0.25px;
  color: #63686b;
}

.general-input-box {
  width: 530px;
  height: 60px;
  border: 1px solid #c2c7c8;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 20px 20px 20px 20px;
}

.warning-block {
  height: 31px;
  width: 35px;
  padding-bottom: 100px;
  margin: 3px 35px 0 0;
  float: left;
}

.reset-warning-div {
  padding-top: 28px;
  display: inline-block;
}

.reset-warning-u {
  cursor: pointer;
}

.general-reset-btn,
.general-reset-btn:hover {
  margin-top: 35px;
  height: 45px;
  width: 189px;
  background-color: #ffa00a;
  border-radius: 5px;
  font-size: 19px;
  line-height: 23px;
  letter-spacing: -0.456px;
  float: right;
  color: #ffffff;
}

.photo-card {
  height: 775px;
  margin-bottom: 55px;
}

.pp-image-container {
  /* 150px */
  margin: 20px auto 70px auto;
}

.editor-canvas {
  border-radius: 50%;
  border: 5px solid rgba(151, 121, 191, 0.6);
}

.scaling-div {
  height: 27px;
  line-height: 27px;
}

.disabletag {
  pointer-events: none;
  cursor: default;
}

.pp-scaler {
  vertical-align: middle;
  margin: -10px 25px 0 25px;
  -webkit-appearance: none;
  width: 300px;
  height: 5px;
  background: #817d7d;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  background: #e4e4e1;
  border-radius: 5.5px;
}

.pp-scaler:hover {
  opacity: 1;
}

.pp-scaler::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #9575cd;
  cursor: pointer;
}

.pp-scaler::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #9575cd;
  cursor: pointer;
}

.minus-scale,
.plus-scale,
.minus-scale:hover,
.plus-scale:hover {
  /* height: 27px; */
  color: #9575cd;
  font-size: 40px;
  background: none;
  border: none;
  cursor: pointer;
  text-decoration: none;
  user-select: none;
}

.pp-button {
  margin: 65px auto 24px auto;
  display: block;
  background-color: #683da1;
  border: none;
  color: #fffffd;
  border-radius: 25px;
  height: 50px;
  width: 275px;
}

.pp-cancel {
  font-family: LatoWeb;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 20px;
  cursor: pointer;
  color: #9575cd;
}

.subscription-card {
  /* height: 337px; */
  width: 730px;
  margin-bottom: 55px;
}

.subscription-card-subscribed {
  /* height: 626px; */
  margin-bottom: 55px;
}

.subscription-body {
  display: block;
}

.subscription-body > label {
  width: 260px;
  height: 60px;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 20px;
  letter-spacing: -0.222083px;
  color: #63686b;
}

.subscription-body > button,
.subscription-body > button:disabled {
  width: 400px;
  height: 60px;
  border: none;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: -0.287402px;
  color: #ffffff;
  background: #ffd14c;
  margin-bottom: 30px;
  box-shadow: none;
}

.subscription-body > input:disabled {
  text-align: center;
  font-size: 44px;
  height: 60px;
  width: 150px;
  background: #fffffd;
  border: 1px solid #c2c7c8;
  margin-bottom: 55px;
  padding-bottom: 10px;
}

.subscriptions > label {
  font-family: LatoWeb;
  font-size: 19px;
  line-height: 23px;
  margin-bottom: 25px;
  /* identical to box height */
}

.unsubscription-div > label {
  font-size: 19px;
  line-height: 23px;
  letter-spacing: 1.00067px;
  color: #63686b;
}

.unsubscribe-span {
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 28px;
  letter-spacing: 0.25px;
}

.unsubscribe-btn,
.unsubscribe-btn:hover {
  font-family: LatoWeb;
  font-size: 19px;
  font-weight: 500;
  line-height: 23px;
  text-align: center;
  color: #fffffd;
  margin-top: 35px;
  width: 300px;
  height: 50px;
  background: #eb1f7f;
  border-radius: 25px;
  float: right;
}

.sub-blue {
  background-image: url('../../images/subscribe_blue.svg');
}

.sub-blue-inp {
  color: #3db2e7;
}

.sub-orange {
  background-image: url('../../images/subscribe_orange.svg');
}

.sub-orange-inp {
  color: #ef962a;
}

.sub-blue-btn:hover {
  background-color: rgba(47, 144, 255, 0.5);
}

.sub-orange-btn:hover {
  background-color: rgba(254, 90, 22, 0.5);
}

.sub-green-btn:hover {
  background-color: rgba(0, 255, 107, 0.5);
}

.sub-green {
  background-image: url('../../images/subscribe_green.svg');
}

.sub-green-inp {
  color: #14c1a0;
}

.subscription-sub-card {
  display: block;
  height: 200px;
  width: 730px;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 20px 20px 25px 20px;
}

.subscription-sub-card > .subscription-sub {
  width: 685px;
  height: 55px;
  margin-bottom: 53px;
}
.subscription-sub > .subscription-sub-1 {
  float: left;
  font-size: 22px;
  line-height: 26px;
  color: #fffffd;
}

.subscription-sub-1 > label {
  font-family: LatoWeb;
  font-size: 22px;
  line-height: 26px;
}

.subscription-sub-1 > p {
  font-family: LatoWeb;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
}

.subscription-sub > input {
  background: #fffffd;
  float: right;
  border-radius: 5px;
  border: none;
  padding: 6px 11px 8px 11px;
  height: 37px;
  width: 120px;
  font-family: LatoWeb;
  font-size: 19px;
  line-height: 23px;
  font-weight: 900;
  text-align: center;
}

.subscription-sub-card > button {
  border-radius: 5px;
  height: 45px;
  width: 220px;
  border: 1px solid #dedede;
  font-family: LatoWeb;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.19);
  user-select: none;
}

.reminders-card {
  height: 675px;
  margin-bottom: 55px;
}

.reminders-body > h5 {
  margin-top: 20px;
}

.reminders-area-div {
  display: block;
  height: 60px;
  width: 658px;
  margin: 35px 0 120px 0;
}

.reminders-left {
  display: block;
  float: left;
  margin: 0 34px 0 0;
  width: 289px;
}

.reminders-left > label {
  margin: 0 0 25px 0;
  display: block;
  width: auto;
}

.reminders-right {
  display: block;
  float: right;
  width: 335px;
  /* background-color: green; */
}

.day-btn {
  width: 43px !important;
  height: 43px !important;
  margin: 0 0 0 5px !important;
}

.reminders-input {
  margin-top: 12px;
  padding: 20px;
}

.reminders-motivation-div {
  display: block;
  margin: 35px 0 12px 0;
  width: 390px;
  height: 42px;
  overflow: visible !important;
}

.sounds-exit-card {
  height: 546px;
  margin-bottom: 55px;
}

.switch-div {
  margin-top: 20px;
  margin-bottom: 50px;
}

.switch-setting-sv {
  width: 297px;
  height: 24px;

  font-family: LatoWeb;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 20px;
  letter-spacing: -0.222083px;

  display: block;
  color: #63686b;
  margin-bottom: 23px;
}

.switch-setting-reminders > label {
  width: 297px;
  display: block;
  float: left;
}

.switch-setting {
  display: block;
  /* float: right; */
}

.switch-setting > label {
  width: auto;
  margin-left: 42px;
}

/* End Right Panel */

.modal-content {
  width: 600px;
  /* height: 255px; */
  border-radius: 5px;
  padding: 25px 30px 15px 25px;
}

.modal-title-exit {
  color: #202528;
  font-size: 19px;
  line-height: 23px;
  letter-spacing: 1.00067px;
  padding-bottom: 21px;
}

.modal-header-exit {
  margin-bottom: 21px;
}

.modal-header-reset {
  padding: 0;
}

.modal-header-exit,
.modal-body-exit {
  padding: 0px;
}

.modal-header-refund,
.modal-body-refund {
  padding: 10px 0px 10px 0px;
}

.modal-body-reset {
  padding-right: 0px;
  padding-left: 0px;
}

.modal-body-reset > p {
  line-height: 23px;
  letter-spacing: -0.248211px;
  color: #63686b;
  margin-bottom: 55px;
}

.modal-body-refund > p {
  line-height: 23px;
  letter-spacing: -0.248211px;
  color: #63686b;
  margin-bottom: 25px;
}

.modal-body-exit {
  padding-bottom: 10px;
}

.modal-body-exit > p {
  line-height: 23px;
  /* identical to box height */
  font-size: 19px;
  letter-spacing: -0.248211px;
  color: #63686b;
  margin-bottom: 75px;
}

.modal-btn-ok,
.modal-btn-close,
.modal-btn-exit,
.modal-btn-ok:hover,
.modal-btn-close:hover,
.modal-btn-exit:hover {
  cursor: pointer;
  color: #fffffd;
  border-radius: 5px;
  height: 45px;
}

.modal-btn-ok {
  width: 120px;
  background-color: #838b90;
}

.modal-btn-close {
  width: 149px;
  margin-right: 20px;
  background-color: #838b90;
}

.modal-btn-exit {
  width: 172px;
  background-color: #ffa00a;
}

.mb-5 {
  margin-bottom: 36px !important;
}

#progress-top-1 {
  position: absolute;
  top: 0px;
  left: 320px;
  width: 15px;
  height: 30px;
  background-color: #9575cd;
}

#progress-top-2 {
  position: absolute;
  top: 0px;
  left: 320px;
  width: 15px;
  height: 15px;
  background-image: linear-gradient(#e5e3ea, #b4a0d8, #9575cd);
}

#progress-bottom {
  position: absolute;
  bottom: 1px;
  left: 320px;
  width: 25px;
  height: 106px;
  background-image: linear-gradient(transparent, #fffffd);
}

/* Topics */
.topic-rules-body {
  position: relative;
  overflow: hidden;
  padding: 55px 35px 21px 35px;
}

.topic-rules-body html,
body {
  min-width: auto !important;
  background: none !important;
}

.topic-rules-body html p,
table {
  font-size: 110% !important;
}

/* Notifications */
.notification-body {
  padding: 55px 35px 55px 35px;
}

.notification-area-div {
  margin-bottom: 29px;
}

.notification-area-div > label {
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 20px;
  letter-spacing: -0.222083px;
  color: #63686b;
  margin-bottom: 27px;
}

.notification-area-div > p {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.169829px;
  color: #afafaf;
}

.notifications-none-div {
  margin-top: 20px;
}

.notifications-none-div > img {
  margin-bottom: 61px;
}

.notifications-none-div > p {
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.222083px;

  color: #63686b;
}

.to-blitz-btn {
  background: #eb1f7f;
  border-radius: 25px;
  font-size: 19px;
  line-height: 23px;
  color: #fffffd;
  height: 50px;
  margin-bottom: 20px;
  width: 730px;
  outline: 0 !important;
  appearance: none !important;
  border: none;
  cursor: pointer !important;
}

.to-blitz-btn:disabled {
  background: #d46398;
  border: none !important;
  cursor: default !important;
  box-shadow: none !important;
}

.lesson-preload-images {
  position: absolute;
  top: -100px;
  left: -100px;
  width: 10px;
  height: 10px;
  background: url('../../images/lesson_available_hover_ic.svg'),
    url('../../images/lesson_complete_hover_ic.svg'),
    url('../../images/extra_words_hover_ic.svg');
}

.theme-done {
  margin-left: 100px;
}
