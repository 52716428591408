.part-one {
  height: 598px;
  background-color: #ebebeb;
}

.part-one .center-column {
  width: 1110px;
  height: 598px;
  background-image: url(../../images/main_bg.svg);
  margin: auto;
}

.part-duex {
  height: 600px;
  background-color: #f4f3f9;
}

.phone {
  position: relative;
  top: 20px;
  float: right;
  margin-right: -41.5px;
}

.part-one .center-column > h3 {
  display: inline-block;
  margin-top: 125px;
  font-size: 45px;
  font-weight: 800;
  color: #ffa00a;
}

.part-one .center-column > p {
  margin-top: 30px;
  font-size: 21px;
  line-height: 1.8;
  color: #737373;
  margin-bottom: 68px;
}

.store-button {
  width: 213px;
  height: 64px;
  border-radius: 10px;
  border: solid 1px #afafaf;
  padding: 1px;
  background-color: #ffffff;
  display: inline-block;
  margin-right: 25px;
  cursor: pointer;
}

.store-button:hover {
  border: solid 2px #000;
  padding: 0px;
}

.appstore {
  margin-top: 7px;
  margin-left: 13px;
  width: 181px;
  height: 50px;
}

.googleplay {
  margin-top: 8px;
  margin-left: 16px;
  width: 173px;
  height: 48px;
}

.part-duex .center-column > h3 {
  margin: 55px auto 55px auto;
  text-align: center;
  font-family: LatoWeb;
  font-size: 35px;
  font-weight: bold;
  color: #494947;
}

.block {
  width: 350px;
  height: 497px;
  border-radius: 10px;
  padding: 0.1px 35px 0px 35px;

  -webkit-box-shadow: 0 20px 20px -20px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0 20px 20px -20px rgba(0, 0, 0, 0.4);
  box-shadow: 0 20px 20px -20px rgba(0, 0, 0, 0.4);
}

.block > h4 {
  font-size: 29px;
  font-weight: bold;
  line-height: 1.05;
  margin-bottom: 34px;
}

.block > p {
  font-size: 19px;
  font-weight: 500;
  line-height: 1.21;
}

.part-duex .center-column .blocks {
  margin: auto;
  width: 1110px;
  color: white;
  margin-bottom: 125px;
  text-align: center;
}

.part-duex .block-1 {
  float: left;
  background-color: #348fe0;
}

.part-duex .block-2 {
  margin: auto;
  background-color: #f29400;
}

.part-duex .block-3 {
  float: right;
  background-color: #9078c1;
}

.part-duex .circle {
  margin: 35px auto 26px auto;
  width: 128px;
  height: 128px;
  background-color: #ffffff;
  text-align: center;
}

.part-duex .circle > img {
  margin: auto;
  vertical-align: middle;
}

.part-duex .helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.part-trois {
  width: 920px;
  height: 260px;
  margin: 125px auto 125px auto;
}

.part-trois > .center-column > h3 {
  margin-bottom: 25px;
}

.part-quatre {
  width: 1110px;
  height: 684px;
  margin: 35px auto 26px auto;
}

.part-quatre > h3 {
  margin: 0 auto 55px auto;
  text-align: center;
  font-family: LatoWeb;
  font-size: 35px;
  font-weight: bold;
  color: #494947;
}

.part-quatre > .left-column {
  width: 635px;
  height: 390px;
  font-family: 'LatoWebLight';
  font-style: normal;
  font-weight: 300;
  font-size: 19px;
  line-height: 30px;
  color: #1f1f1f;
  float: left;
}
.part-quatre > .left-column > p {
  margin-bottom: 30px;
}
.part-quatre > .right-column {
  width: 475px;
  height: 390px;
  padding-left: 66px;
  float: right;
}

.right-column > div {
  margin-top: 3px;
  margin-bottom: 40px;
}

.vl-divider {
  background-color: #9078c1;
  height: 62px;
  width: 7px;
  border-radius: 4px;
  margin-right: 35px;
  margin-top: 5px;
  float: left;
}

.vl-text {
  font-style: normal;
  font-weight: normal;
  height: 69px;
  width: 367px;
  line-height: 23px;
  font-size: 19px;
  color: #191919;
}

.part-five {
  background: #ffa00a;
  border-radius: 10px;
  margin: 35px auto 75px auto;
  width: 1110px;
  height: 95px;
  padding: 23px 35px 22px 30px;
}

.main-user-ic {
  height: 44px;
  width: 44px;
  margin-bottom: 10px;
}

.part-five > .center-column > label {
  font-size: 24px;
  font-weight: 500;
  margin: 7px 85px auto 24px;
  color: #fffffd;
}

.try-it {
  background: #fffffd;
  border-radius: 25px;
  font-size: 19px;
  font-weight: 800;
  line-height: 23px;
  color: #ffa00a;
  width: 270px;
  height: 50px;
  border: none !important;
  outline: 0 !important;
}

.part-trois > .center-column {
  text-align: center;
}

/* .flag-img-display {
  margin-right: 30px;
  margin-top: 30px;
  height: 160px;
  width: 160px;
} */

.flag-container {
  height: 160px;
  width: 160px;
  border: 2px solid #f29400;
  border-radius: 50%;
  background: #fffffd;
  text-align: center;
  font-size: 15px;
  line-height: 18px;
  color: #494947;

  margin-right: 30px;
  margin-top: 30px;
  display: inline-block;
}

.flag-container:nth-child(6),
.flag-container:nth-child(11),
.flag-container:nth-child(16) {
  margin-right: 0;
}

.flag-img {
  width: 75px;
  height: 44px;
  text-align: center;
  margin: 38px auto 25px auto;
}
